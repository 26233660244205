import React from 'react';
import { NavLink } from 'react-router-dom';

import { Divider } from '@abit/ui/components/Divider';
import { SpaceBox } from '@abit/ui/components/SpaceBox';

import { ReactComponent as LogoutIcon } from 'assets/icons/24/logout.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/24/settings.svg';
import { ReactComponent as SvgLogo } from 'assets/logoSvg/LogoImgVert.svg';
import { ReactComponent as SvgMenu } from 'assets/icons/24/menu.svg';
import { logout, logoutUrl } from 'api/auth';
import { getEnrichInput } from 'api/config';
import { Button } from './Button';
import { Header } from './Header';
import { RoundButton } from './RoundButton';
import { Container, LogoWrapper, Navigation, ScrollContainer } from './styled';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { EPage } from 'types/app';
import actions from 'store/actions';

interface IProps {
  onClose: () => void;
}
export const Content: React.FC<IProps> = ({ onClose }) => {
  const { visible: isVisible } = useAppSelector((state) => state.app.sidebar);
  const apps = useAppSelector((state) => state.app.apps);

  const dispatch = useAppDispatch();

  const handleExit = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    try {
      await logout();

      dispatch(actions.app.setPage(EPage.login));
    } catch (err) {
      window.location.href = getEnrichInput(logoutUrl);
    }
  };

  return (
    <Container>
      <Navigation>
        <RoundButton state={isVisible ? 'pressed' : undefined} icon={<SvgMenu />} onClick={onClose} />
        <LogoWrapper>
          <SvgLogo />
        </LogoWrapper>
      </Navigation>
      <ScrollContainer>
        <SpaceBox mb={8}>
          <Header />
          <Divider mx={4} />
          {apps.map(({ name, _id, path, icon }) => (
            <Button
              key={_id}
              as={NavLink}
              mb={1}
              to={path}
              onClick={onClose}
              icon={
                <div
                  style={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: '#939393',
                    WebkitMaskImage: `url(${icon})`,
                    maskImage: `url(${icon})`,
                  }}
                />
              }
            >
              {name}
            </Button>
          ))}
        </SpaceBox>

        <SpaceBox pb={[2, 2, 0]}>
          <Button as={NavLink} to="/settings" icon={<SettingIcon />} mb={2} onClick={onClose}>
            Settings
          </Button>
          <Button as="a" href={getEnrichInput(logoutUrl)} icon={<LogoutIcon />} onClick={handleExit}>
            Log out
          </Button>
        </SpaceBox>
      </ScrollContainer>
    </Container>
  );
};
