/* eslint-disable no-undef */
import { EPage } from 'types/app';

export const mapAvailableURLs: Record<string, EPage> = {
  '/': EPage.main,
  '/login': EPage.login,
};

export const mapAvailablePage: Record<EPage, string> = (() => {
  const keys = Object.keys(mapAvailableURLs);

  const result = {} as Record<EPage, string>;

  keys.forEach((key) => {
    result[mapAvailableURLs[key]] = key;
  });

  return result;
})();
