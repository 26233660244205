import css from '@styled-system/css';
import styled from 'styled-components';

export const Container = styled.div(
  css({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  })
);

export const InfoBlock = styled.div(
  css({
    textAlign: 'center',
    width: '300px',
  })
);

export const ImageWrapper = styled.div(
  css({
    size: [300, 350, 400],
    mb: 10,
  })
);
