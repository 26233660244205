import React, { useState } from 'react';
import { Text } from '@abit/ui/components/Text';
import { getTimeAgo } from '@abit/ui/utils';
import { PersonCard } from '@abit/ui/components/PersonCard';
import { ErrorCard } from '@abit/ui/components/ErrorCard';

import { Loader } from 'components/common/Loader';
import { useInit } from './hooks';
import { Content } from './styled';
import { PersonCardModal } from './PersonCardModal';

export const Users: React.FC = () => {
  const [selectedID, setSelectedID] = useState<string>();
  const { loading, data, refresh } = useInit();

  return (
    <>
      <Text variant="h3Semibold" mb={4} pl={3}>
        Users
      </Text>
      {loading || !data ? (
        <Loader />
      ) : (
        <Content>
          {selectedID && (
            <PersonCardModal id={selectedID} onClose={() => setSelectedID(undefined)} data={data} />
          )}

          {!data && <ErrorCard refresh={refresh} py={10} />}
          {data?.map(({ name, id, lastLoginDate }) => {
            // const secondary = `ID: ${id} • ${role} `;
            const lastLoginDateFormatted = lastLoginDate ? getTimeAgo(lastLoginDate) : 'no data';
            const secondary = 'last login ' + lastLoginDateFormatted;

            return (
              <PersonCard key={id} name={name} secondary={secondary} onClick={() => setSelectedID(id)} />
            );
          })}
        </Content>
      )}
    </>
  );
};
