import { useEffect, useRef } from 'react';
import actions from 'store/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isBlockedTag } from 'utils/keyboard';

export const useKeyboardListener = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector((state) => state.app.searchBar.visible);

  const visibleRef = useRef<boolean>();
  visibleRef.current = isVisible;

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (!['Slash', 'Escape'].includes(event.code)) return;

      if (event.code === 'Escape' && visibleRef.current) {
        dispatch(actions.app.toggleSearchBar());
      } else if (event.code === 'Slash') {
        const hasModal = (window.abit?.modalCount || 0) > 0;
        const blockedTag = isBlockedTag((event.target as HTMLElement)?.tagName);

        if (hasModal || blockedTag) return;

        dispatch(actions.app.toggleSearchBar());
      }
    };

    window.addEventListener('keyup', handler);

    return () => window.removeEventListener('keyup', handler);
  }, [dispatch]);
};
