import { IInit } from './types';
import { apiGet } from './../config';

export const getInitialize = (signal?: AbortSignal): Promise<IInit> => {
  return apiGet<IInit>('/v0/auth/initialize', { signal });
};

export const logoutUrl = '/v0/auth/logout';

export const logout = (): Promise<void> => {
  return apiGet(`${logoutUrl}?noredirect`);
};
