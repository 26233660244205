import { Text } from '@abit/ui/components/Text';
import React, { useState } from 'react';
import { Roles } from './blocks/Roles';
import { Users } from './blocks/Users';
import {
  AppsAndInfoContainer,
  Container,
  EmptyBlock,
  GroupsWrapper,
  LeftSide,
  RightSide,
  UsersWrapper,
} from './styled';
import { Logs } from './blocks/Logs';
import { SpaceBox } from '@abit/ui/components/SpaceBox';
import { ExternalApp } from '@abit/ui/components/ExternalApp';
import { Button } from '@abit/ui/components/Button';
import { Input } from '@abit/ui/components/Input';
import { useAppSelector } from 'store/hooks';
import { AppCard } from 'components/common/AppCard';
import { CarouselBlock } from './blocks/CarouselBlock';

const TestExternalWidget: React.FC<any> = ({ onClose, taskId }) => (
  <ExternalApp
    app="tasks"
    widget="viewTask"
    loadFallback="Loading..."
    onErrorFallback={() => 'error'}
    props={{ taskId, onClose }}
  />
);

export const Main: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [widgetVisible, setWidgetVisible] = useState(false);

  const apps = useAppSelector((state) => state.app.apps);

  return (
    <Container>
      <AppsAndInfoContainer>
        <LeftSide>
          <SpaceBox mx={3}>
            <Text variant="body1Regular">Welcome to the ABIT Platform!</Text>
            <Text variant="body1Regular" color="textSecondary" mb={10}>
              Добро пожаловать на ABIT Platform!
            </Text>
          </SpaceBox>

          <SpaceBox mx={3} mb={10}>
            <Text variant="h3Semibold" mb={4}>
              Apps
            </Text>
            {apps.map(({ iconActive, name, path, _id }) => (
              <AppCard size="m" key={_id} src={iconActive} name={name} path={path} />
            ))}
          </SpaceBox>
        </LeftSide>
        <RightSide>
          <CarouselBlock />
          <SpaceBox mb={10}>
            <Input
              fullWidth
              mb={4}
              placeholder="Find by task ID (XX-1)"
              name="taskId-text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />

            <Button variant="secondary" size="s" onClick={() => setWidgetVisible(!widgetVisible)}>
              {widgetVisible ? 'Unmount' : 'Open widget'}
            </Button>
            {widgetVisible && (
              <TestExternalWidget onClose={() => setWidgetVisible(!widgetVisible)} taskId={inputValue} />
            )}
          </SpaceBox>
        </RightSide>
      </AppsAndInfoContainer>

      <SpaceBox mb={10} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <UsersWrapper>
          <Users />
        </UsersWrapper>
        <GroupsWrapper>
          <Roles />
        </GroupsWrapper>
      </SpaceBox>

      <SpaceBox mx={3}>
        <Text variant="h3Semibold" mb={4}>
          Analytics
        </Text>
        <EmptyBlock mb={10} />

        <Logs />
      </SpaceBox>
    </Container>
  );
};

export default Main;
