import css from '@styled-system/css';
import styled from 'styled-components';

export const Container = styled.div(
  css({
    mb: 10,
    borderRadius: 15,
    overflow: 'hidden',
  })
);
