import css from '@styled-system/css';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';

export const Container = styled.div(
  css({
    px: [5, 8, 10],
    py: [2, 4, 6],
  })
);

export const AppsContainer = styled.div<SpaceProps>(
  css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',

    '> *': {
      mr: 4,
      mb: 4,
    },
  })
);
