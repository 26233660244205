import { memo, useState } from 'react';
import { MainContainer, AppWidthController } from './styled';
import { Header } from './Header';
import { Main } from './Main';
import { Sidebar } from './Sidebar';
import { Notifications } from './Notifications';
import { SearchBar } from './SearchBar';
import { useBreadcrumbsState } from './hooks';

export const Content = memo(() => {
  const [pageTitle, setPageTitle] = useState('');
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbsState();

  return (
    <>
      <Header title={pageTitle} breadcrumbs={breadcrumbs} />
      <MainContainer as={AppWidthController}>
        <Main setBreadcrumbs={setBreadcrumbs} setPageTitle={setPageTitle} />
      </MainContainer>
      <Sidebar />
      <SearchBar />
      <Notifications />
    </>
  );
});
