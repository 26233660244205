import { memo } from 'react';
import { Text } from '@abit/ui/components/Text';
import { DialogPortal } from '@abit/ui/components/DialogPortal';
import { UnstyledButton } from '@abit/ui/components/UnstyledButton';
import { ButtonIcon } from '@abit/ui/components/Modal/styled';
import { ReactComponent as SvgClose } from '@abit/ui/icons/24/close.svg';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import actions from 'store/actions';
import { Background, Container, Header } from './styled';

export const Notifications: React.FC = memo(() => {
  const dispatch = useAppDispatch();

  const visible = useAppSelector((state) => state.app.notifications.visible);

  const toggleVisible = () => dispatch(actions.app.toggleNotifications());

  if (!visible) return null;

  return (
    <DialogPortal>
      <Background onClick={toggleVisible} />
      <Container>
        <Header>
          <Text variant="h3Semibold">Notifications</Text>
          <UnstyledButton as={ButtonIcon} onClick={toggleVisible}>
            <SvgClose />
          </UnstyledButton>
        </Header>
      </Container>
    </DialogPortal>
  );
});
