import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';

export const ToastPortal = () => {
  return createPortal(
    <ToastContainer
      position="top-right"
      autoClose={5000}
      limit={3}
      toastClassName="react-toast"
      className="react-toast-container"
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />,
    document.body
  );
};
