import css from '@styled-system/css';
import styled from 'styled-components';
import { Button } from './Button';
import { space } from 'styled-system';
import { SIDEBAR_WIDTH } from '../consts';

export const Container = styled.aside(
  ({ theme }) =>
    css({
      position: 'fixed',
      width: SIDEBAR_WIDTH,
      height: '100%',
      flexShrink: 0,
      flexGrow: 0,
      display: 'flex',
      flexDirection: 'column',
      px: [2, 3],
      py: 4,
      zIndex: theme.zIndices.modal,
      backgroundColor: 'rgb(255, 255, 255, 0.95)',
      backdropFilter: 'blur(12px)',
      willChange: 'transform, opacity, transition',
    }),
  space
);

export const LeftHeader = styled.div(
  css({
    py: 8,
    px: 6,
    mb: [0, 1, 2, 3, 6, 8],
  })
);

export const ArrowButton = styled.button({
  float: 'right',
});

export const AnchorButton = styled(Button)({
  ':hover': {
    backgroundColor: 'transparent',
  },
});

export const Navigation = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    mb: 2,

    '> *': { flexShrink: 0, lineHeight: 0 },
  })
);
export const LogoWrapper = styled.div(
  css({
    ml: [2, 4, 5],

    '> svg': { width: [60, 88, 96] },

    '> svg > path': {
      fill: 'primary',
    },
  })
);

export const Background = styled.div(({ theme }) =>
  css({
    position: 'fixed',
    marginLeft: '260px',
    bg: 'black30',
    height: '100dvh',
    width: '100dvw',
    backdropFilter: 'blur(1px)',
    zIndex: theme.zIndices.modal,
  })
);

export const ScrollContainer = styled.div({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,
});
