import { BreadcrumbState } from '@abit/ui/types/window';
import { useState, useEffect } from 'react';

export const useBreadcrumbsState = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbState[]>([]);

  useEffect(() => {
    if (!window.abit) return;

    window.abit.setBreadcrumb = (breadcrumb) => {
      setBreadcrumbs((state) => {
        if (breadcrumb.level !== undefined) {
          state[breadcrumb.level] = breadcrumb;
          return [...state];
        }

        const findIndex = state.findIndex(({ id }) => id === breadcrumb.id);
        if (findIndex) {
          state[findIndex] = breadcrumb;
          return [...state];
        }

        const newState = [...state, breadcrumb];
        return newState;
      });
    };

    window.abit.deleteBreadcrumb = ({ id, level }) => {
      if (!id && !level) return;

      setBreadcrumbs((state) =>
        state.filter(
          (breadcrumb: BreadcrumbState | undefined, index) => breadcrumb?.id !== id && index !== level
        )
      );
    };

    return () => {
      if (!window.abit) return;

      window.abit.setBreadcrumb = () => {};
      window.abit.deleteBreadcrumb = () => {};
    };
  }, []);

  return { breadcrumbs, setBreadcrumbs };
};
