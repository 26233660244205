import { NavLink } from 'react-router-dom';
import { Text } from '@abit/ui/components/Text';

import { AppCardContainer, IconApp } from './styled';
import SuperEllipse from 'react-superellipse';

interface IProps {
  src: string;
  name: string;
  path: string;
  size: 'l' | 'm';
}

export const AppCard: React.FC<IProps> = ({ src, name, path, size }) => (
  <NavLink to={path}>
    <AppCardContainer as={SuperEllipse} p1={1} p2={35} $size={size}>
      <IconApp $src={src} $size={size} />
      <Text variant="body1Regular" mt={4}>
        {name}
      </Text>
    </AppCardContainer>
  </NavLink>
);
