import { useCallback, useState } from 'react';
import { Container, Input, MotionStyled, SuperEllipseStyled } from './styled';
import { SpaceBox } from '@abit/ui/components/SpaceBox';
import { Text } from '@abit/ui/components/Text';
import { Divider } from '@abit/ui/components/Divider';
import SuperEllipse from 'react-superellipse';

const variants = {
  initial: { opacity: 0, scale: 1.2 },
  animate: { opacity: 1, scale: 1, transition: { duration: 0.15, ease: 'backOut' } },
  exit: { opacity: 0, scale: 1.1, transition: { duration: 0.3, ease: 'backInOut' } },
};

let timerId: number;

export const Content: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const [superEllipse, setSuperEllipse] = useState(false);

  const handleAnimationStart = useCallback((step: keyof typeof variants) => {
    clearTimeout(timerId);
    if (step === 'animate') {
      timerId = window.setTimeout(() => setSuperEllipse(true), variants.animate.transition.duration * 1000);
    } else {
      timerId = window.setTimeout(() => setSuperEllipse(false), variants.exit.transition.duration * 1000);
    }
  }, []);

  return (
    <MotionStyled
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      onAnimationStart={handleAnimationStart}
    >
      <Container>
        <SuperEllipseStyled as={superEllipse ? SuperEllipse : 'div'} p1={1} p2={35}>
          <Input
            placeholder="Search"
            autoFocus
            value={searchText}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
          {searchText && (
            <>
              <Divider my={3} mx={6} />
              <SpaceBox px={6} pt={3} pb={6}>
                <Text variant="body2Regular">Function not available</Text>
              </SpaceBox>
            </>
          )}
        </SuperEllipseStyled>
      </Container>
    </MotionStyled>
  );
};
