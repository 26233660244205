import css from '@styled-system/css';
import styled from 'styled-components';

const NOTIFICATION_WIDTH = 320;

export const FullScreen = styled.div({
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: 1000,
  left: 0,
  paddingTop: 80,
});

export const Background = styled.div(
  css({
    position: 'fixed',
    right: 0,
    bg: 'rgb(255 255 255 / 50%)',
    width: NOTIFICATION_WIDTH,
    backdropFilter: 'blur(5px)',
    boxShadow: '0 0 10px 5px rgb(255 255 255 / 50%)',
    borderRadius: '50px',
    minHeight: 200,
    willChange: 'opacity, transition',
  })
);

export const Container = styled.div({
  position: 'fixed',
  right: 0,
  width: NOTIFICATION_WIDTH,
});

export const NotFoundText = styled.div(({ theme }) =>
  css({
    py: 20,
    textAlign: 'center',
    color: 'black50',
    willChange: 'opacity, transition',
    ...theme.typography.extraBodyRegular,
  })
);
