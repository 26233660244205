export const formatDate = (date: Date | string | number) => {
  const formatter = new Intl.DateTimeFormat('ru-RU');

  if (typeof date === 'string') {
    const dateObj = new Date(date);

    if (isNaN(dateObj.getTime())) return 'Invalid';

    return formatter.format(dateObj);
  }

  return formatter.format(date);
};

export const formatDateTime = (date: Date | string | number) => {
  const formatter = new Intl.DateTimeFormat('ru-RU', { timeStyle: 'medium', dateStyle: 'short' });

  if (typeof date === 'string') {
    const dateObj = new Date(date);

    if (isNaN(dateObj.getTime())) return 'Invalid';

    return formatter.format(dateObj);
  }

  return formatter.format(date);
};
