import { useState } from 'react';
import { Container, Input, MotionStyled, Wrapper } from './styled';
import { SpaceBox } from '@abit/ui/components/SpaceBox';
import { Text } from '@abit/ui/components/Text';
import { Divider } from '@abit/ui/components/Divider';
import { Variants } from 'framer-motion';

const variants: Variants = {
  initial: {
    opacity: 0,
    scale: 1.2,
  },
  animate: { opacity: 1, scale: 1, transition: { duration: 0.15, ease: 'backOut' } },
  exit: {
    opacity: 0,
    scale: 1.1,
    transition: { duration: 0.3, ease: 'backInOut' },
  },
};

export const Content: React.FC = () => {
  const [searchText, setSearchText] = useState('');

  return (
    <MotionStyled variants={variants} initial="initial" animate="animate" exit="exit">
      <Container>
        <Wrapper>
          <Input
            placeholder="Search"
            autoFocus
            value={searchText}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
          {searchText && (
            <>
              <Divider my={3} mx={6} />
              <SpaceBox px={6} pt={3} pb={6}>
                <Text variant="body2Regular">Function not available</Text>
              </SpaceBox>
            </>
          )}
        </Wrapper>
      </Container>
    </MotionStyled>
  );
};
