import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@abit/ui/components/Button';

import { ReactComponent as ErrorImage } from 'assets/404.svg';
import { Container, ImageWrapper, InfoBlock } from './styled';

export const NotFound: React.FC = () => (
  <Container>
    <ImageWrapper>
      <ErrorImage />
    </ImageWrapper>
    <InfoBlock>
      <Button as={Link} size="m" to="/">
        Go Home
      </Button>
    </InfoBlock>
  </Container>
);
