import React from 'react';

import { AppCard } from 'components/common/AppCard';

import { Container, AppsContainer } from './styled';
import { useAppSelector } from 'store/hooks';

export const Apps: React.FC = () => {
  const apps = useAppSelector((state) => state.app.apps);

  return (
    <Container>
      <AppsContainer>
        {apps.map(({ iconActive, name, path, _id }) => (
          <AppCard size="l" key={_id} src={iconActive} name={name} path={path} />
        ))}
      </AppsContainer>
    </Container>
  );
};
