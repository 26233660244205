import css from '@styled-system/css';
import styled from 'styled-components';

export const Loader = styled.div(
  css({
    fontSize: '20px',
    color: 'sidebarColor',
    bg: 'sidebarBg',
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  })
);
