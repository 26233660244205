import React, { memo, useCallback, useState } from 'react';
import { DialogPortal } from '@abit/ui/components/DialogPortal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import actions from 'store/actions';
import { Background } from './styled';

import { useAutoHideSidebar, useKeyboardListener } from './hooks';
import { PortalButton } from './PortalButton';
import { Content } from './Content';

export const Sidebar: React.FC = memo(() => {
  useAutoHideSidebar();
  useKeyboardListener();

  const [portalMode, setPortalMode] = useState(true);

  const { visible: isVisible } = useAppSelector((state) => state.app.sidebar);

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(actions.app.toggleSidebar());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isVisible) return <PortalButton />;

  if (portalMode)
    return (
      <DialogPortal>
        <Background onClick={handleClose} />
        <Content onClose={handleClose} />
      </DialogPortal>
    );

  return <Content onClose={handleClose} />;
});
