import React, { ElementType, ReactNode } from 'react';
import { SpaceProps } from 'styled-system';
import { ButtonWrapper, IconWrapper } from './styled';

interface IProps<T extends React.ElementType> extends SpaceProps {
  icon?: ReactNode;
  children?: ReactNode;
  state?: 'pressed';
  as?: T;
}

export const RoundButton = <T extends ElementType = 'button'>({
  icon,
  children,
  state,
  ...props
}: IProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof IProps<T>>) => (
  <ButtonWrapper {...props} data-state={state}>
    {icon && <IconWrapper>{icon}</IconWrapper>}
    {children && <span>{children}</span>}
  </ButtonWrapper>
);
