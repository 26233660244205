export const getEnrichInput = (input: string) => {
  if (input[0] === '/') return `/back${input}`;

  return input;
};

const apiInstance = <R>(input: RequestInfo | URL, init?: RequestInit): Promise<R> => {
  return window.fetch(input, init).then((response) => {
    const { status } = response;

    if (status === 200) return response.json() as R;

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  });
};

export const apiGet = async <R>(input: string, init?: RequestInit): Promise<R> => {
  return apiInstance<R>(getEnrichInput(input), { ...init, method: 'GET' });
};

export const apiPost = async <R, B = unknown>(input: string, body: B, init?: RequestInit): Promise<R> => {
  return apiInstance<R>(getEnrichInput(input), { ...init, method: 'POST', body: JSON.stringify(body) });
};
