import { DialogPortal } from '@abit/ui/components/DialogPortal';
import { Background } from './styled';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import actions from 'store/actions';
import { memo } from 'react';
import { Content } from './Content';
import { motion, AnimatePresence } from 'framer-motion';
import { bgMotionVariants } from 'animations';

export const SearchBar: React.FC = memo(() => {
  const dispatch = useAppDispatch();

  const visible = useAppSelector((state) => state.app.searchBar.visible);

  const toggleVisible = () => dispatch(actions.app.toggleSearchBar());

  return (
    <AnimatePresence>
      {visible && (
        <DialogPortal>
          <Background
            as={motion.div}
            variants={bgMotionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            onClick={toggleVisible}
          />
          <Content />
        </DialogPortal>
      )}
    </AnimatePresence>
  );
});
