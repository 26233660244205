import React, { useState } from 'react';

import { ReactComponent as SvgLogoImg } from 'assets/logoSvg/LogoImg.svg';
import { Background, ContainerLogo, Container } from './styled';
import { SignInContent } from './SignInContent';
import { CreateContent } from './CreateContent';

export const Login: React.FC = () => {
  const [signInMode, setSignInMode] = useState(true);

  return (
    <Background>
      <Container>
        <ContainerLogo>
          <SvgLogoImg />
        </ContainerLogo>
        {signInMode ? (
          <SignInContent onCreateClick={() => setSignInMode(false)} />
        ) : (
          <CreateContent onSignInClick={() => setSignInMode(true)} />
        )}
      </Container>
    </Background>
  );
};
