import React from 'react';
import { ReactComponent as LoaderSvg } from './loading.svg';
import { AnimategWrapper, Container } from './styled';

export const Loader: React.FC = () => {
  return (
    <Container>
      <AnimategWrapper>
        <LoaderSvg />
      </AnimategWrapper>
    </Container>
  );
};
