import css from '@styled-system/css';
import { UnstyledButton } from '@abit/ui/components/UnstyledButton';
import styled from 'styled-components';

export const Wrapper = styled.div(css({ mb: 4, mt: [3, 10] }));

export const AvatarStyled = styled.div(
  css({
    display: 'inline-block',
  })
);

export const UserButton = styled(UnstyledButton)(
  css({
    mb: 6,
    color: 'textSecondary',
    width: '100%',

    ':hover': {
      color: 'primary',
    },

    ':active': {
      color: 'primaryPressed',
    },
  })
);

export const UserNameStyled = styled.div(
  css({
    mt: [2, 2, 3],
  })
);
