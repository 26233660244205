import css from '@styled-system/css';
import styled from 'styled-components';
import { NOTIFICATION_WIDTH } from './consts';

export const Background = styled.div(
  { height: '100vh', width: `calc(100vw - ${NOTIFICATION_WIDTH})` },
  css({
    mr: NOTIFICATION_WIDTH,
    bg: 'black30',
    height: '100dvh',
    width: `calc(100dvw - ${NOTIFICATION_WIDTH})`,
    backdropFilter: 'blur(1px)',
  })
);
export const Container = styled.aside(({ theme }) =>
  css({
    position: 'fixed',
    right: 0,
    width: NOTIFICATION_WIDTH,
    height: '100%',
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    px: [2, 3],
    zIndex: theme.zIndices.modal,
    backgroundColor: 'rgb(255, 255, 255, 0.68)',
    backdropFilter: 'blur(12px)',
  })
);

export const Header = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  height: 64,
  alignItems: 'center',
});
