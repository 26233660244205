import { ILog, getLogs } from 'api/logs';
import { useEffect, useState } from 'react';

export const useFetchData = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ILog[] | null>(null);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await getLogs();
      setData(response.logs);
    } catch (err) {
      // no important
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { loading, data, refresh: fetchData };
};
