import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useOutlet, useLocation } from 'react-router-dom';

const pageTransitionVariants: Variants = {
  initial: { opacity: 0, scale: 0.97 },
  animate: { opacity: 1, scale: 1, transition: { duration: 0.4, ease: 'circInOut' } },
  exit: { opacity: 0, transition: { duration: 0.3, ease: 'circOut' } },
} as const;
export const AnimatedOutlet: React.FC = () => {
  const outlet = useOutlet();

  const location = useLocation();
  const appKey = location.pathname.split('/')[1];

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={appKey}
        variants={pageTransitionVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {outlet}
      </motion.div>
    </AnimatePresence>
  );
};
