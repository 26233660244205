import css from '@styled-system/css';
import styled from 'styled-components';

import AppNoIconImage from 'assets/icons/128/app-no-icon.svg?url';

export const AppCardContainer = styled.div<{ $size: 'l' | 'm' }>(({ theme, $size }) =>
  css({
    display: 'inline-block',
    color: 'textPrimary',
    textAlign: 'center',
    padding: $size === 'l' ? [2, 4, 6] : [2, 4],
    borderRadius: 15,
    flexShrink: 0,
    flexGrow: 0,

    ':hover': {
      color: 'primary',
      background: `linear-gradient(0deg, ${theme.colors.sidebarButtonBgActive} 0%, ${theme.colors.secondaryBgHover} 100%)`,
    },
  })
);

export const IconApp = styled.div<{ $src: string | null; $size: 'l' | 'm' }>(({ $src, $size }) =>
  css({
    margin: '0 auto',
    size: $size === 'l' ? [64, 96, 128] : [64, 96],
    backgroundImage: `url(${$src || AppNoIconImage})`,
    backgroundSize: 'contain',
  })
);
