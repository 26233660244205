import ReactDOM from 'react-dom';
import { ReactComponent as SvgMenu } from 'assets/icons/24/menu.svg';
import actions from 'store/actions';
import { useAppDispatch } from 'store/hooks';
import { RoundButton } from '../Content/RoundButton';
import { useEffect, useState } from 'react';

export const PortalButton: React.FC = () => {
  const [refreshFlag, setRefreshFlag] = useState(false);

  const dispatch = useAppDispatch();

  const handleToggleSidebar = () => {
    dispatch(actions.app.toggleSidebar());
  };

  const Portal = <RoundButton mr={[0, 2, 3, 3, 4]} icon={<SvgMenu />} onClick={handleToggleSidebar} />;

  const container = document.getElementById('aside-button');

  useEffect(() => {
    if (container) return;

    const timerId = window.setTimeout(() => setRefreshFlag(!refreshFlag), 1000);

    return () => window.clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  if (!container) return null;

  return ReactDOM.createPortal(Portal, container);
};
