import { memo } from 'react';
import { Text } from '@abit/ui/components/Text';
import { useAppSelector } from 'store/hooks';
import { Content } from './Content';
import { EmptyBlock } from 'pages/Main/styled';

export const Logs = memo(() => {
  const available = useAppSelector((state) => state.app.features?.logs);

  return (
    <>
      <Text variant="h3Semibold" mb={4}>
        Logs
      </Text>
      <Text variant="body1Regular" mb={4} color="textSecondary">
        It is information about on how use Logs.
      </Text>
      {available ? <Content /> : <EmptyBlock mb={10} />}
    </>
  );
});
