import { Button } from '@abit/ui/components/Button';
import { Text } from '@abit/ui/components/Text';
import { Input } from '@abit/ui/components/Input';
import { Label } from '@abit/ui/components/Label';
import { Form, H1, LinksContainer } from '../styled';
import { useState } from 'react';

interface IProps {
  onSignInClick: () => void;
}

export const CreateContent: React.FC<IProps> = ({ onSignInClick }) => {
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [surname, setSurname] = useState('');

  const handleSubmit = () => {
    // dispatch(actions.app.setPage(EPage.main));
  };

  return (
    <>
      <Text as={H1} variant="h2Semibold">
        Request to create <span>ABIT ID</span>
      </Text>
      <Form method="post" action="/back/v0/auth/registration">
        <Label fullWidth mb={5}>
          <Text variant="body2Regular">Email</Text>
          <Input
            fullWidth
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            required
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </Label>
        <Label fullWidth>
          <Text variant="body2Regular">Name</Text>
          <Input
            fullWidth
            placeholder="Name"
            name="name"
            value={name}
            required
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Label>
        <Label fullWidth>
          <Text variant="body2Regular">Surname</Text>
          <Input
            fullWidth
            placeholder="Surname"
            name="surname"
            value={surname}
            required
            onChange={(e) => setSurname(e.currentTarget.value)}
          />
        </Label>
        <Label mt={5} fullWidth>
          <Text variant="body2Regular">Username</Text>
          <Input
            fullWidth
            placeholder="Username"
            name="login"
            value={login}
            required
            onChange={(e) => setLogin(e.currentTarget.value.replace(/[^a-z0-9-]/i, ''))}
          />
        </Label>
        <Label mb={10} fullWidth>
          <Text variant="body2Regular">Password</Text>
          <Input
            fullWidth
            placeholder="Password"
            name="pass"
            type="password"
            required
            value={pass}
            onChange={(e) => setPass(e.currentTarget.value)}
          />
        </Label>
        <Button mb={10} type="submit" onClick={handleSubmit}>
          Send request
        </Button>
      </Form>
      <LinksContainer>
        <Text as="a" href="#" mb={[4, 4, 4, 2]} variant="body2Regular" title="Открывается в новом окне.">
          Forgotten your ABIT ID or password?
        </Text>
        <Text
          as="a"
          href="#"
          onClick={(event) => {
            event.preventDefault();
            onSignInClick();
          }}
          variant="body2Regular"
        >
          Sign In
        </Text>
      </LinksContainer>
    </>
  );
};
