import css from '@styled-system/css';
import styled from 'styled-components';

export const Container = styled.header(css({}));

export const External = styled.div(
  css({
    position: 'fixed',
    top: 0,
    flexGrow: 0,
    flexShrink: 0,
    height: '64px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white50',
    backdropFilter: 'blur(12px)',
    width: '100%',
    zIndex: '100',
  }),

  ({ theme }) => ({
    animation: 'launcher-header-shadow linear both',
    animationRange: '0 64px',
    animationTimeline: 'scroll()',
    '@keyframes launcher-header-shadow': {
      to: {
        boxShadow: `0 0px 10px ${theme.colors.black15}`,
      },
    },
  })
);

export const ExternalContainer = styled.div(({ theme }) =>
  css({
    px: theme.contentMarginX,
    display: 'flex',
    justifyContent: 'space-between',
  })
);

export const LeftSide = styled.div(
  css({
    display: 'flex',
    mx: '-8px',
  })
);

// export const Background = styled.div(
//   {
//     '@property --header-gradient-deg': {
//       syntax: '"<angle>"',
//       inherits: 'false',
//       initialValue: '190deg',
//     },
//     '@property --header-gradient-color': {
//       syntax: '"<color>"',
//       inherits: 'false',
//       initialValue: 'transparent',
//     },
//     '@property --header-gradient-position': {
//       syntax: '"<percentage>"',
//       inherits: 'false',
//       initialValue: '70%',
//     },
//     '@keyframes Animation': {
//       '0%': {
//         '--header-gradient-deg': '190deg',
//         '--header-gradient-position': '70%',
//       },
//       '50%': {
//         '--header-gradient-deg': '420deg',
//         '--header-gradient-position': '10%',
//         '--header-gradient-color': '#FD8F00',
//       },
//       '100%': {
//         '--header-gradient-deg': '190deg',
//         '--header-gradient-position': '70%',
//       },
//     },
//   },
//   css({
//     // animation: 'Animation 150s ease infinite',
//     mt: 60,

//     transition: '--header-gradient-deg 1ms, --header-gradient-position 1s',
//     background:
//       'linear-gradient(var(--header-gradient-deg), rgb(0, 102, 255, 0.13) var(--header-gradient-position), var(--header-gradient-color) 100%) 30% 0px;',
//   })
// );

// INFO: https://scroll-driven-animations.style
export const BackgroundContainer = styled.div(({ theme }) =>
  css({
    pt: 72,
    px: theme.contentMarginX,
    pb: 6,

    animation: 'linear animate-in-and-out',
    animationTimeline: 'view()',

    '@keyframes animate-in-and-out': {
      'exit 0%': {
        opacity: 1,
        transform: 'translateY(0)',
      },
      'exit 10%': {
        opacity: 1,
      },
      'exit 35%': {
        opacity: 0,
      },
      'exit 75%': {
        transform: 'translateY(-100%)',
      },
    },
  })
);

export const Buttons = styled.div(
  css({
    display: 'flex',
    justifyContent: 'space-evenly',
  })
);
