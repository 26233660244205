import css from '@styled-system/css';
import styled from 'styled-components';

export const Background = styled.div(
  {
    width: '100vw',
    minHeight: '100vh',
  },
  {
    width: '100dvw',
    minHeight: '100dvh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  {
    '@property --header-gradient-deg': {
      syntax: '"<angle>"',
      inherits: 'false',
      initialValue: '172deg',
    },
    '@property --header-gradient-color': {
      syntax: '"<color>"',
      inherits: 'false',
      initialValue: '#fed2e6',
    },
    '@property --header-gradient-position': {
      syntax: '"<percentage>"',
      inherits: 'false',
      initialValue: '50%',
    },
    '@keyframes LoginAnimation': {
      '0%': {
        '--header-gradient-deg': '172deg',
        '--header-gradient-position': '50%',
      },
      '25%': {
        '--header-gradient-deg': '180deg',
        '--header-gradient-position': '60%',
        '--header-gradient-color': '#ffdaaa',
      },
      '50%': {
        '--header-gradient-deg': '188deg',
        '--header-gradient-position': '50%',
        '--header-gradient-color': '#fed2e6',
      },
      '68%': {
        '--header-gradient-deg': '180deg',
        '--header-gradient-position': '60%',
        '--header-gradient-color': '#ffdaaa',
      },
      '100%': {
        '--header-gradient-deg': '172deg',
        '--header-gradient-position': '50%',
        '--header-gradient-color': '#fed2e6',
      },
    },
  },
  css({
    animation: 'LoginAnimation 30s linear infinite',
    transition: '--header-gradient-deg 1ms, --header-gradient-position 1s',
    background:
      'linear-gradient(var(--header-gradient-deg), rgb(0, 102, 255, 0.15) var(--header-gradient-position), var(--header-gradient-color) 110%)',
  })
);
export const Container = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: [220, 220, 220, 300],

    h1: {
      fontWeight: 500,
    },
  })
);

export const ContainerLogo = styled.div(
  css({
    mb: [6, 6, 8, 8, 10],
    mt: 10,
    textAlign: 'center',
  })
);

export const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
});

export const LinksContainer = styled.div(
  css({
    mb: 15,
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  })
);

export const H1 = styled.h1(({ theme }) =>
  css({
    textAlign: 'center',
    mb: [6, 6, 8, 8, 10],
    textShadow: `1px 1px 3px ${theme.colors.black30}`,
  })
);
