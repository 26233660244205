import { Modal, Body } from '@abit/ui/components/Modal';
import { PersonCard } from '@abit/ui/components/PersonCard';
import { Text } from '@abit/ui/components/Text';
import { getTimeAgo } from '@abit/ui/utils';
import { IUser } from 'api/users';
import { Cell, Label, Value } from './styled';
import { ActivationButton } from './ActivationButton';

interface IProps {
  id: string;
  data: IUser[];
  onClose: () => void;
}

export const PersonCardModal: React.FC<IProps> = ({ id, data, onClose }) => {
  const foundUser = data.find((user) => user.id === id);

  const lastLoginDateFormatted = foundUser?.lastLoginDate ? getTimeAgo(foundUser.lastLoginDate) : 'no data';
  const secondary = 'last login ' + lastLoginDateFormatted;

  return (
    <Modal size="s" onClose={onClose}>
      <Body>
        {!foundUser ? (
          <Text color="textFailed" variant="body1Regular">
            No data
          </Text>
        ) : (
          <>
            <PersonCard mb={8} name={foundUser.name} secondary={secondary} />

            <Cell>
              <Label>ID</Label>
              <Value>{foundUser.id}</Value>
            </Cell>
            {foundUser.activationDate ? (
              <Cell>
                <Label>Activated date</Label>
                <Value>{foundUser.activationDate}</Value>
              </Cell>
            ) : (
              <>
                <Text mt={6} mb={2} variant="body2Regular">
                  Profile is not actived
                </Text>
                <ActivationButton id={foundUser.id} />
              </>
            )}
          </>
        )}
      </Body>
    </Modal>
  );
};
