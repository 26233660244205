import React, { memo, useDeferredValue } from 'react';
import { Carousel } from 'react-responsive-carousel';
import SuperEllipse from 'react-superellipse';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import space1Image from 'assets/images/space1.png';
import space2Image from 'assets/images/space2.png';
import { Container } from './styled';

export const CarouselBlock: React.FC = memo(() => {
  return useDeferredValue(
    <SuperEllipse p1={1} p2={35}>
      <Container>
        <Carousel
          autoPlay
          showThumbs={false}
          dynamicHeight
          showStatus={false}
          emulateTouch
          infiniteLoop
          interval={10000}
        >
          <div>
            <img src={space1Image} />
          </div>
          <div>
            <img src={space2Image} />
          </div>
        </Carousel>
      </Container>
    </SuperEllipse>
  );
});
