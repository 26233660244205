import { Button } from '@abit/ui/components/Button';
import { Text } from '@abit/ui/components/Text';
import { CheckBox } from '@abit/ui/components/CheckBox';
import { Input } from '@abit/ui/components/Input';
import { Label } from '@abit/ui/components/Label';
import { Form, H1, LinksContainer } from '../styled';
import { useState } from 'react';

interface IProps {
  onCreateClick: () => void;
}

export const SignInContent: React.FC<IProps> = ({ onCreateClick }) => {
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [keepMe, setKeepMe] = useState(false);

  const handleSubmit = () => {
    // dispatch(actions.app.setPage(EPage.main));
  };

  const keepMeSwitch = () => {
    setKeepMe((prevState) => !prevState);
  };
  return (
    <>
      <Text as={H1} variant="h2Semibold">
        Sign in with <span>ABIT ID</span>
      </Text>
      <Form method="post" action="/back/v0/auth/login">
        <Label mb={3} fullWidth>
          <Text variant="body2Regular">Username</Text>
          <Input
            fullWidth
            placeholder="Username"
            name="login"
            value={login}
            required
            onChange={(e) => setLogin(e.currentTarget.value)}
          />
        </Label>
        <Label mb={5} fullWidth>
          <Text variant="body2Regular">Password</Text>
          <Input
            fullWidth
            placeholder="Password"
            name="password"
            type="password"
            required
            value={pass}
            onChange={(e) => setPass(e.currentTarget.value)}
          />
        </Label>
        <CheckBox mb={10} mx={2} checked={keepMe} onChange={keepMeSwitch} name="keep-me">
          Keep me signed in
        </CheckBox>
        <Button mb={10} type="submit" onClick={handleSubmit}>
          Sign in
        </Button>
      </Form>
      <LinksContainer>
        <Text as="a" href="#" mb={[4, 4, 4, 2]} variant="body2Regular" title="Открывается в новом окне.">
          Forgotten your ABIT ID or password?
        </Text>
        <Text
          as="a"
          href="#"
          onClick={(event) => {
            event.preventDefault();
            onCreateClick();
          }}
          variant="body2Regular"
        >
          Create ABIT ID
        </Text>
      </LinksContainer>
    </>
  );
};
