import css from '@styled-system/css';
import styled from 'styled-components';
import { SEARCH_BAR_WIDTHS } from './consts';

export const Background = styled.div(
  { height: '100vh', width: `100vw` },
  css({
    mr: SEARCH_BAR_WIDTHS,
    bg: 'black30',
    height: '100dvh',
    width: `100dvw`,
    backdropFilter: 'blur(1px)',
  })
);
