import css from '@styled-system/css';
import styled from 'styled-components';

export const LoaderStyled = styled.div({
  cursor: 'pointer',
});

export const Title = styled.div(
  css({
    mb: 2,
    color: 'textSecondary',
  })
);
