import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { theme } from '@abit/ui/theme';
import { GlobalStyle } from '@abit/ui/theme/globalStyle';
// import { RuntimeLoader } from '@rive-app/react-canvas-lite';
import 'react-toastify/dist/ReactToastify.css';

import { Login } from 'pages/Login';
import { Error } from 'pages/Error';
import { EPage } from 'types/app';
import { useAppSelector } from 'store/hooks';

import { useInit, useURLController } from './hooks';
import { Content } from './Content';
import { Loader } from './styled';
import { ToastPortal } from './ToastPortal';

const LoadComponent = () => <Loader>Loading...</Loader>;

export const App: React.FC = memo(() => {
  const navigate = useNavigate();

  const page = useAppSelector((state) => state.app.page);
  const isInit = useAppSelector((state) => state.app.isInit);

  useURLController(navigate);
  useInit(navigate);

  const renderContent = () => {
    if (!isInit) return <LoadComponent />;

    if (page === EPage.login) return <Login />;

    if (page === EPage.error) return <Error />;

    return <Content />;
  };

  // useEffect(() => {
  //   RuntimeLoader.setWasmUrl('/rive.wasm');
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastPortal />
      {renderContent()}
    </ThemeProvider>
  );
});

App.displayName = 'App';
