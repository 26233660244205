import React, { ElementType, ReactNode } from 'react';
import { SpaceProps } from 'styled-system';
import { ButtonWrapper, IconWrapper } from './styled';

export interface IProps<T extends React.ElementType> extends SpaceProps {
  icon?: ReactNode;
  children?: ReactNode;
  as?: T;
}

export const Button = React.forwardRef(
  <T extends ElementType = 'a'>(
    { icon, children, ...props }: IProps<T> & Omit<React.ComponentPropsWithRef<T>, keyof IProps<T>>,
    ref: React.ForwardedRef<HTMLAnchorElement>
  ) => (
    <ButtonWrapper ref={ref} {...props}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children && <span>{children}</span>}
    </ButtonWrapper>
  )
);
