import { configureStore } from '@reduxjs/toolkit';

import app from './reducers/app';
import login from './reducers/login';

export const store = configureStore({
  reducer: {
    app,
    login,
  },
  devTools: {
    name: 'abit-launcher',
  },
});

// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
