import css from '@styled-system/css';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

interface IButton extends SpaceProps {
  light?: boolean;
}

export const ButtonWrapper = styled.a.withConfig<IButton>({
  shouldForwardProp: (prop) => shouldForwardProp(prop),
})(
  space,
  ({ light, theme }) => (light ? theme.typography.body2Regular : theme.typography.body1Regular),
  ({ light, theme }) =>
    css({
      border: 0,
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      color: 'sidebarColor',
      cursor: 'pointer',
      px: 3,
      background: 'transparent',
      outline: 'none',

      ':hover': {
        bg: !light ? 'sidebarButtonBgHover' : 'transparent',
        color: 'sidebarButtonColorHover',
      },

      '&.active': {
        bg: !light ? 'sidebarButtonBgActive' : 'transparent',
        color: 'sidebarButtonColorActive',
      },

      ':not(:disabled):active, :not(:disabled):active:focus': {
        color: 'sidebarColor',
      },

      ':focus-visible': {
        boxShadow: `0 0 0 4px ${theme.colors.focusOutline}`,
      },
    })
);

export const IconWrapper = styled.span(
  css({
    lineHeight: 0,
    ':not(:last-child)': { mr: 3 },

    path: {
      fill: 'currentColor',
    },
  })
);
