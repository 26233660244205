import css from '@styled-system/css';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from './consts';

export const Background = styled.div(
  { height: '100vh', width: `calc(100vw)` },
  css({
    // marginLeft: '260px',
    bg: 'black30',
    height: '100dvh',
    width: `calc(100dvw)`,
    backdropFilter: 'blur(1px)',
    willChange: 'opacity, transition',
  })
);
