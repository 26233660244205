import React from 'react';
import { Text } from '@abit/ui/components/Text';
import { LoaderStyled, Title } from './styled';
import { Loader } from 'components/App/styled';

export const Error: React.FC = () => {
  return (
    <Loader as={LoaderStyled} onClick={() => window.location.reload()} title="click to update">
      <div>
        <Text variant="h1Bold" as={Title}>
          SERVICE NOT AVAILABLE
        </Text>
        <Text variant="body1Regular">You can try after few minutes.</Text>
      </div>
    </Loader>
  );
};
