/* eslint-disable @typescript-eslint/no-explicit-any */
import { AUTOCLOSE_SIDEBAR_WIDTH_MIN } from 'components/App/Content/Sidebar/consts';
import { useCallback, useEffect } from 'react';
import actions from 'store/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useAutoHideSidebar = () => {
  const isVisible = useAppSelector((state) => state.app.sidebar.visible);

  const dispatch = useAppDispatch();

  const handleCheck = useCallback(() => {
    if (isVisible) {
      if (window.innerWidth <= AUTOCLOSE_SIDEBAR_WIDTH_MIN) dispatch(actions.app.toggleSidebar());
    } else {
      if (window.innerWidth > AUTOCLOSE_SIDEBAR_WIDTH_MIN) dispatch(actions.app.toggleSidebar());
    }
  }, [dispatch, isVisible]);

  useEffect(() => {
    // window.addEventListener('resize', handleCheck);

    return () => window.removeEventListener('resize', handleCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCheck]);
};

export const useKeyboardListener = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector((state) => state.app.sidebar.visible);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.keyCode !== 83 && event.code !== 'KeyS') return;

      const hasBlockedTags = ['input', 'textarea'].includes((event.target as any)?.tagName.toLowerCase());

      const hasModal = (window.abit?.modalCount || 0) > (isVisible ? 1 : 0);

      if (hasBlockedTags || hasModal) return;

      dispatch(actions.app.toggleSidebar());
    };

    window.addEventListener('keypress', handler);

    return () => window.removeEventListener('keypress', handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
};
