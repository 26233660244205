import css from '@styled-system/css';
import styled from 'styled-components';
import { SEARCH_BAR_WIDTHS } from '../consts';
import { motion } from 'framer-motion';

export const Input = styled.input(({ theme }) =>
  css({
    height: 64,
    px: 6,
    border: 0,
    outline: 'none',
    background: 'white',
    borderRadius: 16,
    transition: theme.animations.default,

    ':not([value=""])': {
      borderRadius: 8,
      height: 40,
      m: 5,
      mb: 1,
      boxShadow: `0 0 1px ${theme.colors.black30}`,
    },
  })
);

export const Wrapper = styled.div(
  css({
    width: SEARCH_BAR_WIDTHS,
    backgroundColor: 'rgb(255, 255, 255, 0.88)',
    backdropFilter: 'blur(12px)',
    display: 'flex',
    flexDirection: 'column',
  })
);

export const MotionStyled = styled(motion.div)({
  width: '100%',
  position: 'fixed',
});

export const Container = styled.aside(({ theme }) =>
  css({
    my: [10, 12, 14, 16, 18, 20],
    overflow: 'scroll',

    width: SEARCH_BAR_WIDTHS,
    mx: 'auto',
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: 15,
    boxShadow: `0 5px 15px ${theme.colors.black30}`,

    zIndex: theme.zIndices.modal,
  })
);
