import { useCallback, useEffect, useState } from 'react';

const DESCTOP_WIDTH_MIN = 720;

export const useResize = () => {
  const [mobileMode, setMobileMode] = useState(window.innerWidth < DESCTOP_WIDTH_MIN);

  const handleCheck = useCallback(() => {
    if (!mobileMode) {
      if (window.innerWidth < DESCTOP_WIDTH_MIN) setMobileMode(true);
    } else {
      if (window.innerWidth >= DESCTOP_WIDTH_MIN) setMobileMode(false);
    }
  }, [mobileMode]);

  useEffect(() => {
    window.addEventListener('resize', handleCheck);

    return () => window.removeEventListener('resize', handleCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCheck]);

  return { mobileMode };
};
