import css from '@styled-system/css';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';

export const Content = styled.div<ColorProps>(
  color,
  css({
    height: '100%',
    borderRadius: '20px',
    overflow: 'auto',
    p: 3,
  })
);
