import { AUTOCLOSE_SIDEBAR_WIDTH_MIN } from 'components/App/Content/Sidebar/consts';
import { useCallback, useEffect, useRef } from 'react';
import actions from 'store/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isBlockedTag } from 'utils/keyboard';

export const useAutoHideSidebar = () => {
  const isVisible = useAppSelector((state) => state.app.sidebar.visible);

  const dispatch = useAppDispatch();

  const handleCheck = useCallback(() => {
    if (isVisible) {
      if (window.innerWidth <= AUTOCLOSE_SIDEBAR_WIDTH_MIN) dispatch(actions.app.toggleSidebar());
    } else {
      if (window.innerWidth > AUTOCLOSE_SIDEBAR_WIDTH_MIN) dispatch(actions.app.toggleSidebar());
    }
  }, [dispatch, isVisible]);

  useEffect(() => {
    // window.addEventListener('resize', handleCheck);

    return () => window.removeEventListener('resize', handleCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCheck]);
};

export const useKeyboardListener = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector((state) => state.app.sidebar.visible);

  const visibleRef = useRef<boolean>();
  visibleRef.current = isVisible;

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (!['KeyS', 'Escape'].includes(event.code)) return;

      const blockedTag = isBlockedTag((event.target as HTMLElement)?.tagName);
      if (blockedTag) return;

      if (visibleRef.current) {
        dispatch(actions.app.toggleSidebar());
      } else if (event.code === 'KeyS') {
        const hasModal = (window.abit?.modalCount || 0) > 0;

        if (hasModal) return;

        dispatch(actions.app.toggleSidebar());
      }
    };

    window.addEventListener('keydown', handler);

    return () => window.removeEventListener('keydown', handler);
  }, [dispatch]);
};
