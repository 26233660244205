import React, { memo, useCallback } from 'react';
import { DialogPortal } from '@abit/ui/components/DialogPortal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import actions from 'store/actions';
import { Background } from './styled';

import { useAutoHideSidebar, useKeyboardListener } from './hooks';
import { PortalButton } from './PortalButton';
import { Content } from './Content';
import { AnimatePresence, motion, Variants } from 'framer-motion';

export const bgMotionVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.7, ease: 'backOut' } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: 'backInOut' } },
};

export const Sidebar: React.FC = memo(() => {
  useAutoHideSidebar();
  useKeyboardListener();

  // const [portalMode, setPortalMode] = useState(true);

  const { visible } = useAppSelector((state) => state.app.sidebar);

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(actions.app.toggleSidebar());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence initial mode="wait">
      {visible && (
        <DialogPortal key="sidebar">
          <Background
            as={motion.div}
            variants={bgMotionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            onClick={handleClose}
          />
          <Content onClose={handleClose} />
        </DialogPortal>
      )}
      <PortalButton />
    </AnimatePresence>
  );

  // return <Content onClose={handleClose} />;
});
