import css from '@styled-system/css';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';

export const Container = styled.div<ColorProps>(
  color,
  css({
    height: '800px',
    borderRadius: '20px',
    overflow: 'auto',
  })
);
