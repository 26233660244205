import React, { memo, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ExternalApp } from '@abit/ui/components/ExternalApp';
import { ErrorCard } from '@abit/ui/components/ErrorCard';
import { BreadcrumbState } from '@abit/ui/types/window';

import { Apps } from 'pages/Apps';
import { Main as MainPage } from 'pages/Main';
import { NotFound } from 'components/App/NotFound';
import { useAppSelector } from 'store/hooks';

import { Loader } from '../../styled';
import { Container } from './styled';
import { AnimatedOutlet } from './AnimatedOutlet';

const Settings = React.lazy(() => import('pages/Settings'));

const HeaderWrapper: React.FC<any> = ({
  title,
  children,
  path,
  setPageTitle,
  setBreadcrumbs,
  bg = 'white',
}) => {
  useEffect(() => {
    const newBreadcrumbs: BreadcrumbState[] =
      title !== 'Home'
        ? [
            { name: 'Home', href: '/', id: 'home' },
            { name: title, id: 'appName', href: path },
          ]
        : [{ name: 'Home', id: 'home', href: '/' }];
    setBreadcrumbs(newBreadcrumbs);

    setPageTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    document.body.style.background = bg;
  }, [bg]);

  return children;
};

interface IProps {
  setBreadcrumbs: React.Dispatch<React.SetStateAction<BreadcrumbState[]>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const Main = memo<IProps>(({ setBreadcrumbs, setPageTitle }) => {
  const handleErrorFallback = (reload: () => void) => <ErrorCard py="15%" refresh={reload} />;

  const apps = useAppSelector((state) => state.app.apps);

  return (
    <Container>
      <Routes>
        <Route element={<AnimatedOutlet />}>
          <Route
            key="home"
            index
            element={
              <HeaderWrapper title="Home" setPageTitle={setPageTitle} setBreadcrumbs={setBreadcrumbs}>
                <MainPage />
              </HeaderWrapper>
            }
          />
          {apps.map(({ path, appName, _id, name, background }) => (
            <Route
              key={_id}
              path={path + '/*'}
              element={
                <HeaderWrapper
                  bg={background}
                  title={name}
                  path={path}
                  setPageTitle={setPageTitle}
                  setBreadcrumbs={setBreadcrumbs}
                >
                  <ExternalApp
                    key={_id}
                    app={appName}
                    widget="main"
                    loadFallback={<Loader>Loading...</Loader>}
                    onErrorFallback={handleErrorFallback}
                  />
                </HeaderWrapper>
              }
            />
          ))}
          <Route
            key="apps"
            path="/apps"
            element={
              <HeaderWrapper title="Apps" setPageTitle={setPageTitle} setBreadcrumbs={setBreadcrumbs}>
                <Apps />
              </HeaderWrapper>
            }
          />
          <Route
            key="settings"
            path="/settings/*"
            element={
              <HeaderWrapper title="Settings" setPageTitle={setPageTitle} setBreadcrumbs={setBreadcrumbs}>
                <React.Suspense fallback={<Loader>Loading...</Loader>}>
                  <Settings />
                </React.Suspense>
              </HeaderWrapper>
            }
          />
          <Route key="notFound" path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Container>
  );
});
