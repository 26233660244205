/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactRouterDOM from 'react-router-dom';
import * as StyledComponents from 'styled-components';
import * as ReactToastify from 'react-toastify';
import * as ReactTooltip from 'react-tooltip';

// Добавляем модули в регистр SystemJS по ключам - псевдо-URL, предварительно заданным в Import Map:
// eslint-disable-next-line @typescript-eslint/dot-notation
const loader: any = (window as any)['System'];

loader.set('app:react', React);
loader.set('app:react-dom', ReactDOM);
loader.set('app:react-router-dom', ReactRouterDOM);
loader.set('app:styled-components', StyledComponents);
loader.set('app:react-toastify', ReactToastify);
loader.set('app:react-tooltip', ReactTooltip);
