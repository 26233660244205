import { Button } from '@abit/ui/components/Button';
import { activateUser } from 'api/users';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export const ActivationButton: React.FC<{ id: string }> = ({ id }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await activateUser(id);

      toast.success('Profile activated');
    } catch (e: unknown) {
      toast.error('Error. Please try again.');
    }

    setLoading(false);
  };

  return (
    <Button variant="secondary" size="s" disabled={loading} onClick={handleSubmit}>
      {loading ? 'Loading...' : 'Activate'}
    </Button>
  );
};
