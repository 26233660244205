import { getInitialize } from 'api/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useCallback, useEffect } from 'react';
import { NavigateFunction, useLocation } from 'react-router';
import actions from 'store/actions';
import { EPage } from 'types/app';
import { mapAvailablePage, mapAvailableURLs } from './mapAvailableURLs';
import { toast } from 'react-toastify';

export const useInit = (navigate: NavigateFunction) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const fetchData = useCallback(
    async (signal: AbortSignal) => {
      dispatch(actions.app.setInit(false));

      try {
        const { user, apps, features } = await getInitialize(signal);

        dispatch(actions.app.setUser(user));
        dispatch(actions.app.setApps(apps));
        dispatch(actions.app.setFeatures(features));

        if (location.pathname === mapAvailablePage[EPage.login]) {
          dispatch(actions.app.setPage(EPage.main));

          navigate(mapAvailablePage[EPage.main]);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // TODO: разобраться, почему нельзя error: Response.

        if (signal?.aborted) return;

        if (error.status === 401) {
          if (location.pathname !== mapAvailablePage[EPage.login]) {
            window.localStorage.setItem('last-route', `${location.pathname}${location.search}`);
          }

          dispatch(actions.app.setPage(EPage.login));
          navigate(mapAvailablePage[EPage.login]);
        } else {
          toast(await error.text());
          dispatch(actions.app.setPage(EPage.error));
        }
      }

      dispatch(actions.app.setInit(true));
    },
    [dispatch, location.pathname, location.search, navigate]
  );

  useEffect(() => {
    const controller = new AbortController();

    fetchData(controller.signal);

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useURLController = (navigate: NavigateFunction) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { page, isInit } = useAppSelector((state) => state.app);

  useEffect(() => {
    const lastRoute = localStorage.getItem('last-route');
    if (lastRoute && location.pathname !== mapAvailablePage[EPage.login]) {
      navigate(lastRoute);
      localStorage.removeItem('last-route');
    }

    const currentPage = mapAvailableURLs[location.pathname];
    if (currentPage) {
      dispatch(actions.app.setPage(currentPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInit) return;

    const nextPage = mapAvailablePage[page];
    if (!nextPage) return;

    navigate(nextPage, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
};
