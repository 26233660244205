import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Text } from '@abit/ui/components/Text';

import { ReactComponent as HomeIcon } from 'assets/icons/24/home.svg';
import { ReactComponent as BackIcon } from 'assets/icons/24/chevron_left.svg';
import { Button, IconButton } from './styled';

interface IProps {
  href?: string;
  name: string;
  id: string;
  iconMode?: boolean;
}

export const Breadcrumb = memo<IProps>(({ href, id, name, iconMode }) => {
  if (iconMode) {
    if (id === 'home')
      return (
        <IconButton as={NavLink} to={href || ''}>
          <HomeIcon />
        </IconButton>
      );

    if (id === 'back')
      return (
        <IconButton as={NavLink} to={href || ''}>
          <BackIcon />
        </IconButton>
      );
  }

  const formatedName = name.length > 17 ? `${name.substring(0, 7)}…${name.substring(name.length - 7)}` : name;

  return href ? (
    <Button as={NavLink} to={href}>
      {formatedName}
    </Button>
  ) : (
    <Text variant="body2Regular" color="textPrimary" px={2}>
      {formatedName}
    </Text>
  );
});
