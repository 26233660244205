import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, IApp, IFeatures } from 'api/auth';
import { EPage } from 'types/app';

export interface IState {
  isInit: boolean;
  page: EPage;
  user: IUser | null;
  apps: IApp[];
  features: IFeatures | null;
  sidebar: {
    visible: boolean;
  };
  notifications: {
    visible: boolean;
  };
  searchBar: {
    visible: boolean;
  };
}

const initialState: IState = {
  isInit: false,
  page: EPage.main,
  user: null,
  apps: [],
  features: null,
  sidebar: {
    visible: false,
  },
  notifications: {
    visible: false,
  },
  searchBar: {
    visible: false,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<EPage>) => ({ ...state, page: action.payload }),
    setInit: (state, action: PayloadAction<boolean>) => ({ ...state, isInit: action.payload }),
    setUser: (state, action: PayloadAction<IUser | null>) => ({ ...state, user: action.payload }),
    setFeatures: (state, action: PayloadAction<IFeatures | null>) => ({ ...state, features: action.payload }),
    setApps: (state, action: PayloadAction<IApp[]>) => ({ ...state, apps: action.payload }),
    toggleSidebar: (state) => ({
      ...state,
      sidebar: {
        ...state.sidebar,
        visible: !state.sidebar.visible,
      },
    }),
    toggleNotifications: (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        visible: !state.notifications.visible,
      },
    }),
    toggleSearchBar: (state) => ({
      ...state,
      searchBar: {
        ...state.searchBar,
        visible: !state.searchBar.visible,
      },
    }),
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
