import { apiGet, apiPost } from './../config';
import { IUser } from './types';

export const getAllList = async (): Promise<IUser[]> => {
  const response = await apiGet<IUser[]>('/v0/users/getAllList');

  return response;
};

export const activateUser = async (id: string): Promise<void> => {
  const response = await apiPost<void>('/v0/users/activateUser', { id });

  return response;
};
