import React from 'react';
import { BreadcrumbState } from '@abit/ui/types/window';
import { Text } from '@abit/ui/components/Text';

import { Container } from './styled';
import { Breadcrumb } from './Breadcrumb';
import { useResize } from './hooks';

export const Breadcrumbs = React.memo<{ data: BreadcrumbState[] }>(({ data }) => {
  const { mobileMode } = useResize();

  let preparedData = data;
  if (mobileMode && data.length > 2) {
    preparedData = [data[0], { ...data[data.length - 2], id: 'back' }, data[data.length - 1]];
  }

  const breadcrumbMaxIndex = preparedData.length - 1;

  return (
    <Container>
      {preparedData.map((breadcrumb, index) => {
        if (!breadcrumb) {
          // eslint-disable-next-line no-console
          console.warn(`Breadcrumb ${index} level is missing.`);

          return null;
        }

        const { name, href, id } = breadcrumb;

        return (
          <React.Fragment key={id}>
            {Boolean(index) && (
              <Text mx={1} variant="body2Regular" color="textSecondary">
                /
              </Text>
            )}
            <Breadcrumb
              href={breadcrumbMaxIndex !== index ? href : undefined}
              id={id}
              name={name}
              iconMode={data.length > 1 && mobileMode}
            />
          </React.Fragment>
        );
      })}
    </Container>
  );
});
