import css from '@styled-system/css';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export const ButtonWrapper = styled.button<SpaceProps>(
  space,
  css({
    border: 0,
    height: 40,
    alignItems: 'center',
    borderRadius: '50%',
    color: 'sidebarColor',
    cursor: 'pointer',
    p: 2,
    background: 'transparent',

    ':not(:disabled):hover': {
      bg: 'sidebarButtonBgHover',
      color: 'sidebarButtonColorHover',
    },

    ":not(:disabled)[data-state='pressed']": { color: 'sidebarButtonColorActive' },

    ':not(:disabled):active, :not(:disabled):active:focus': {
      color: 'sidebarColor',
    },
  })
);

export const IconWrapper = styled.span(
  css({
    lineHeight: 0,
    ':not(:last-child)': { mr: 4 },

    path: {
      fill: 'currentColor',
    },
  })
);
