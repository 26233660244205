import { getAllList, IUser } from 'api/users';
import { useEffect, useState } from 'react';

export const useInit = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IUser[] | null>(null);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await getAllList();

      response.sort((a, b) => ((a.lastLoginDate || '') > (b.lastLoginDate || '') ? -1 : 1));

      setData(response);
    } catch (err) {
      // no important
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { loading, data, refresh: fetchData };
};
