import { UnstyledButton } from '@abit/ui/components/UnstyledButton';
import css from '@styled-system/css';
import styled from 'styled-components';

export const Container = styled.section(
  css({
    display: 'flex',
    alignItems: 'center',
  })
);

export const Button = styled(UnstyledButton)(
  css({
    px: 2,
    py: 1,
    color: 'textSecondary',
    borderRadius: 4,

    ':hover': {
      color: 'primary',
      bg: 'secondaryBgHover',
    },

    ':active': {
      color: 'textSecondary',
      bg: 'secondaryBgActive',
    },
  })
);

export const IconButton = styled(Button)(
  css({
    lineHeight: 0,
    px: 1,
    '*': { fill: 'currentColor' },
  })
);
