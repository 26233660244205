import { memo } from 'react';
import { Text } from '@abit/ui/components/Text';
import { Tooltip } from '@abit/ui/components/Tooltip';

import { ReactComponent as SvgNottification } from 'assets/icons/24/notification.svg';
import { ReactComponent as SvgSearch } from 'assets/icons/24/search.svg';
import { ReactComponent as SvgMenu } from 'assets/icons/24/menu.svg';

import {
  // Background,
  BackgroundContainer,
  Buttons,
  Container,
  External,
  ExternalContainer,
  LeftSide,
} from './styled';
import { IHeaderProps } from './types';
import { Breadcrumbs } from './Breadcrumbs';
import { AppWidthController } from '../styled';
import { RoundButton } from '../Sidebar/Content/RoundButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import actions from 'store/actions';
// import { RatingRive } from './RatingRive';

export const Header = memo<IHeaderProps>(({ title, breadcrumbs }) => {
  const notificationsVisible = useAppSelector((state) => state.app.notifications.visible);
  const dispatch = useAppDispatch();

  const toggleNotifications = () => dispatch(actions.app.toggleNotifications());
  const toggleSearchBar = () => dispatch(actions.app.toggleSearchBar());
  const toggleSidebar = () => dispatch(actions.app.toggleSidebar());

  return (
    <Container>
      <External>
        <AppWidthController as={ExternalContainer}>
          <LeftSide>
            <RoundButton mr={[0, 2, 3, 3, 4]} title="Press S" icon={<SvgMenu />} onClick={toggleSidebar} />
            <Breadcrumbs data={breadcrumbs} />
          </LeftSide>
          <section id="app-widget">
            <Buttons>
              {/* <RatingRive /> */}
              <Tooltip
                mx={[2, 3, 4]}
                id="launcher-search-bar"
                trigger={<RoundButton icon={<SvgSearch />} onClick={toggleSearchBar} />}
                place="bottom"
              >
                <Text variant="body1Regular">Search Bar</Text>
              </Tooltip>

              <Tooltip
                id="launcher-notifications"
                trigger={
                  <RoundButton
                    state={notificationsVisible ? 'pressed' : undefined}
                    icon={<SvgNottification />}
                    onClick={toggleNotifications}
                  />
                }
                place="bottom-end"
              >
                <Text variant="body1Regular">Notifications</Text>
              </Tooltip>
            </Buttons>
          </section>
        </AppWidthController>
      </External>
      {/* <Background> */}
      <AppWidthController as={BackgroundContainer}>
        <Text variant="h1Bold" as="h1" m={0}>
          {title}
        </Text>
      </AppWidthController>
      {/* </Background> */}
    </Container>
  );
});
