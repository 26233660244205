import styled from 'styled-components';

export const MainContainer = styled.div({
  display: 'flex',
});

export const СontentWidthLimiter = styled.div({
  width: '100%',
  maxWidth: 1600,
  margin: '0 auto',
});
