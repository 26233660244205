import { Text } from '@abit/ui/components/Text';
import { Loader } from 'components/common/Loader';
import React, { useState } from 'react';
import { Content } from './styled';
import { PersonCard } from '@abit/ui/components/PersonCard';

const groups = [
  {
    id: '1',
    name: 'Maintainer',
    accessCount: 8,
  },
  {
    id: '2',
    name: 'Design',
    accessCount: 3,
  },
  {
    id: '3',
    name: 'Developer',
    accessCount: 6,
  },
];

const maxAccess = 8;

export const Roles: React.FC = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Text variant="h3Semibold" mb={4} ml={3} onClick={() => setLoading(!loading)}>
        Roles
      </Text>
      {loading ? (
        <Loader />
      ) : (
        <Content>
          {groups.map(({ name, accessCount, id }) => {
            const secondary = `access ${accessCount} of ${maxAccess} • apps all`;

            return <PersonCard key={id} name={name} secondary={secondary} onClick={() => null} />;
          })}
        </Content>
      )}
    </>
  );
};
