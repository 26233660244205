import { typography } from '@abit/ui/theme/typography';
import css from '@styled-system/css';
import styled from 'styled-components';

export const TableStyled = styled.table(
  css({
    border: 0,
    ...typography.captionRegular,

    '> tbody > tr:nth-of-type(2n+1)': {
      bg: 'secondaryBgActive',
    },
  })
);
