import { ErrorCard } from '@abit/ui/components/ErrorCard';
import { Loader } from 'components/common/Loader';
import React, { memo } from 'react';
import { useFetchData } from '../hooks';
import { Container } from '../styled';
import { Table } from './Table';
import { Charts } from './Charts';

export const Content: React.FC = memo(() => {
  const { loading, data, refresh } = useFetchData();

  if (loading) return <Loader />;

  return (
    <Container>
      {!data ? (
        <ErrorCard refresh={refresh} py={10} />
      ) : (
        <>
          <Charts data={data} />
          <Table data={data} />
        </>
      )}
    </Container>
  );
});
