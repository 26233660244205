import { memo } from 'react';
import { DialogPortal } from '@abit/ui/components/DialogPortal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import actions from 'store/actions';
import { Background, Container, FullScreen, NotFoundText } from './styled';
import { useKeyboardListener } from './hooks';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { bgMotionVariants } from 'animations';

const notFoundMotionVariants: Variants = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: 1, transition: { duration: 0.3, ease: 'circOut' } },
  exit: bgMotionVariants.exit,
};

export const Notifications: React.FC = memo(() => {
  useKeyboardListener();

  const dispatch = useAppDispatch();

  const visible = useAppSelector((state) => state.app.notifications.visible);

  const toggleVisible = () => dispatch(actions.app.toggleNotifications());

  return (
    <AnimatePresence mode="wait">
      {visible && (
        <DialogPortal key="notifications">
          <FullScreen as={motion.div} onClick={toggleVisible}>
            <Background
              as={motion.div}
              variants={bgMotionVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            />
            <Container as={motion.div}>
              <NotFoundText
                as={motion.div}
                variants={notFoundMotionVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                No notifications
              </NotFoundText>
            </Container>
          </FullScreen>
        </DialogPortal>
      )}
    </AnimatePresence>
  );
});
