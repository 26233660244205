import css from '@styled-system/css';
import styled from 'styled-components';

export const Cell = styled.div(
  css({
    display: 'flex',
    mb: 2,
  })
);
export const Label = styled.div(css({ color: 'textSecondary', minWidth: 160 }));
export const Value = styled.div(css({ minWidth: 160 }));
