import React, { useEffect, useRef } from 'react';
import { Text } from '@abit/ui/components/Text';
import { Avatar } from '@abit/ui/components/Avatar';

import { ReactComponent as HomeIcon } from 'assets/icons/24/home.svg';
import { ReactComponent as ApplicationsIcon } from 'assets/icons/24/applications.svg';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import actions from 'store/actions';
import { Wrapper, UserButton, UserNameStyled, AvatarStyled } from './styled';
import { Button } from '../Button';
import { NavLink } from 'react-router-dom';
import { getInitials } from '@abit/ui/utils';

export const Header = React.memo(() => {
  const user = useAppSelector((state) => state.app.user);

  const dispatch = useAppDispatch();

  const mainButtonRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    mainButtonRef.current?.focus();
  }, []);

  const handleClose = () => {
    dispatch(actions.app.toggleSidebar());
  };

  return (
    <>
      <Wrapper>
        <UserButton>
          <AvatarStyled>
            <Avatar size="xl" initials={getInitials(user?.name || '')} />
          </AvatarStyled>
          <Text as={UserNameStyled} variant="body1Regular">
            {user?.name || 'нет данных'}
          </Text>
        </UserButton>
      </Wrapper>
      <Button ref={mainButtonRef} as={NavLink} to="/" icon={<HomeIcon />} mb={1} onClick={handleClose}>
        Home
      </Button>
      <Button as={NavLink} to="/apps" icon={<ApplicationsIcon />} mb={8} onClick={handleClose}>
        Apps
      </Button>
    </>
  );
});
