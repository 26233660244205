import css from '@styled-system/css';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export const UsersWrapper = styled.div(
  css({
    height: '350px',
    pr: 5,
    mb: [10, 10, 10, 0],
    flex: '1 1',
    flexBasis: ['100%', '100%', '100%', '50%'],
    display: 'flex',
    flexDirection: 'column',
  })
);

export const GroupsWrapper = styled.div(
  css({
    height: '350px',
    pl: 2,
    flex: '1 1',
    flexBasis: ['100%', '100%', '100%', '50%'],
    display: 'flex',
    flexDirection: 'column',
  })
);

export const Container = styled.div(
  css({
    px: [2, 5, 7],
    py: [2, 4, 6],
  })
);

export const EmptyBlock = styled.div<SpaceProps>(
  css({
    width: '100%',
    height: 200,
    borderRadius: 20,
    bg: 'disabledBg',
  }),
  space
);

export const AppsAndInfoContainer = styled.div(
  css({
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
  })
);

export const LeftSide = styled.div(
  css({
    flexGrow: 1,
  })
);

export const RightSide = styled.div(
  css({
    px: [4, 6, 0],
    mx: 'auto',
    width: ['100%', '100%', 200, 200, 200, 250, 250, 270],
    maxWidth: 270,
    flexShrink: 0,
  })
);
